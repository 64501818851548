import axios from "axios";
import { createContext, useContext, useEffect, useState } from "react";
import {backendApi} from '../constant'

const AuthContex=createContext()
const AuthProvider=({children})=>{
    const [isAdmin,setIsAdmin]=useState(false)
    const [isLogin,setIsLogin]=useState(false)
    return <AuthContex.Provider value={{isAdmin,setIsAdmin,isLogin,setIsLogin}}>{children}</AuthContex.Provider>
}
export {AuthContex,AuthProvider}