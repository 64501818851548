
import { Nav, NavLink, NavbarContainer, Span, NavLogo, NavItems, GitHubButton, ButtonContainer, MobileIcon, MobileMenu, MobileNavLogo, MobileLink } from './NavbarStyledComponent'
import { FaBars } from 'react-icons/fa';
import { useTheme } from 'styled-components';
import myImage from './logo.png';
import { PopupWidget } from 'react-calendly';
import { useNavigate } from 'react-router-dom';
import { forwardRef, useContext, useRef, useState } from 'react';
import { Alert, Box, Button, Modal, TextField, Typography } from '@mui/material';
import styled from 'styled-components'
import axios from 'axios';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

const ContactForm = styled.form`
  width: 95%;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.card};
  padding: 32px;
  border-radius: 16px;
  box-shadow: rgba(23, 92, 230, 0.15) 0px 4px 24px;
  margin-top: 28px;
  gap: 12px;
`

const ContactTitle = styled.div`
  font-size: 24px;
  margin-bottom: 6px;
  font-weight: 600;
  color: ${({ theme }) => theme.text_primary};
`
const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [logOut, setLogOut] = useState(false)
 const navigate=useNavigate()
 const calendlyRef = useRef();
 
  return (<>   
    <Nav>
      <NavbarContainer>
        <NavLogo to='/'>
          <span style={{ display: 'flex', alignItems: 'center', color: 'white', marginBottom: '20;', cursor: 'pointer' }} >
            <img src={myImage} alt="Logo" style={{ width: '5rem', height: '5rem' }} />
            <Span>Aminurmus Technology</Span>
          </span>
        </NavLogo>
        <MobileIcon>
          <FaBars onClick={() => setIsOpen(!isOpen)} />
        </MobileIcon>
        <NavItems>
          <NavLink href="/" >Home</NavLink>
          <NavLink href="/services">Services</NavLink>
          <NavLink href="/career">Careers</NavLink>
          <NavLink href="/industries">Industries</NavLink>
          <NavLink href="/testimonials" >Testimonials</NavLink>
          <NavLink href="/blog">Blog</NavLink>
          <NavLink href="/" >About Us</NavLink>
        </NavItems>
        <ButtonContainer>
        </ButtonContainer>
        {isOpen && (
          <MobileMenu isOpen={isOpen}>
            <MobileLink  onClick={() => navigate('/')}>Home</MobileLink>
            <MobileLink onClick={() => navigate('/services')}>Services</MobileLink>
            <MobileLink onClick={() => navigate('/career')}>Careers</MobileLink>
            <MobileLink  onClick={() => navigate('/industries')}>Industries</MobileLink>
            <MobileLink onClick={() => navigate('/testimonials')}>Testimonials</MobileLink>
            <MobileLink  onClick={() => navigate('/blog')}>Blog</MobileLink>
            <MobileLink onClick={() => navigate('/')}>About Us</MobileLink>
          </MobileMenu>
        )}
      </NavbarContainer>
      <CalendlyComponent ref={calendlyRef} />      
    </Nav>
  </>
  );
};
const CalendlyComponent = forwardRef((_, ref) => (
  <div className="popup-widget">
    <PopupWidget
      url="https://calendly.com/aminurmustechnology/30min"
      rootElement={document.getElementById('root')}
      text="Let's Connect"
      textColor="#ffffff"
      color="-webkit-linear-gradient(225deg, hsla(259, 100%, 50%, 1) 0%, hsla(489, 100%, 50%, 1) 100%)"
    />
    <button ref={ref} style={{ display: 'none' }}>Trigger Calendly</button>
  </div>
));
export default Navbar;