import { ThemeProvider } from "styled-components";
import { useState, useEffect } from "react";
import { darkTheme, lightTheme } from './utils/Themes.js'
import Navbar from "./components/Navbar";
import './App.css';
import { BrowserRouter as Router } from 'react-router-dom';
import { Route, Routes } from 'react-router-dom';
import HeroSection from "./components/VideoSection";
import Skills from "./components/Skills";
import Contact from "./components/Contact";
import Footer from "./components/Footer";
import styled from "styled-components";
import WhatWeDo from "./components/WhatWeDo"
import Testimonial from "./components/Testimonial"
import OurClient from "./components/OurClient"
import Career from './Pages/Career'
import ApplyForm from './Pages/ApplyForm'
import Industries from "./Pages/Industries/index.js";
import Blog from "./Pages/Blog"
import Services from "./Pages/Services"
import { AuthProvider } from "./contex/AuthContex.js";
import PaymentForm from "./Pages/PaymentForm"
import Slider from "./components/Testimonial";


const Body = styled.div`
  background-color: ${({ theme }) => theme.bg};
  width: 100%;
  overflow-x: hidden;
`

const Wrapper = styled.div`
  background: linear-gradient(38.73deg, rgba(204, 0, 187, 0.15) 0%, rgba(201, 32, 184, 0) 50%), linear-gradient(141.27deg, rgba(0, 70, 209, 0) 50%, rgba(0, 70, 209, 0.15) 100%);
  width: 100%;
  clip-path: polygon(0 0, 100% 0, 100% 100%,30% 98%, 0 100%);
`
function App() {
  const [darkMode, setDarkMode] = useState(false);
  const [openModal, setOpenModal] = useState({ state: false, project: null });
  console.log(openModal)
  return (
    <AuthProvider>
      <ThemeProvider theme={darkMode ? darkTheme : lightTheme}>
        <Router >
          <Navbar />
          <Routes>
            <Route path="" element={
              <Body>
                <HeroSection />
                <Wrapper>
                  <Skills />
                </Wrapper>
                <WhatWeDo />
                <Testimonial />
                <OurClient />
                <Wrapper>
                  <Contact />
                </Wrapper>
              </Body>
            } />
            <Route path="applyform/:role/:isfulltime" element={<ApplyForm />} />
            <Route path="career" element={<Career />} />
            <Route path="industries" element={<Industries />} />
            <Route path="testimonials" element={<Slider />} />
            <Route path="blog" element={<Blog />} />
            <Route path="services" element={<Services />} />
            <Route path="aminurmus-payment" element={<PaymentForm />} />
          </Routes>
          <Footer />
        </Router>
      </ThemeProvider>
    </AuthProvider>

  );
}

export default App;
