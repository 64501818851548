import React, { useContext, useEffect, useState } from 'react'
import styles from './myStyles.module.css';
import Typewriter from 'typewriter-effect';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { Alert, Box, Button, FormControl, Grid, InputLabel, Modal, NativeSelect, TextField, Typography } from '@mui/material';
import styled from 'styled-components'
import { AuthContex } from '../../contex/AuthContex';
import axios from 'axios';
import NoPagesFound from '../../components/NoPagesFound';
import CarrerLoading from '../../components/CarrerLoading';
import {BASE_URL} from "../../constant"
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};
const ContactForm = styled.form`
  width: 95%;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.card};
  padding: 32px;
  border-radius: 16px;
  box-shadow: rgba(23, 92, 230, 0.15) 0px 4px 24px;
  margin-top: 28px;
  gap: 12px;
`

const ContactTitle = styled.div`
  font-size: 24px;
  margin-bottom: 6px;
  font-weight: 600;
  color: ${({ theme }) => theme.text_primary};
`
export default function Index() {
  const [blogs, setBlogs] = useState([])
  const [filteredBlogs, setFilteredBlogs] = useState([])
  const [isEditJob, setIsEditJob] = useState(false)
  const [isAddJob, setIsAddJob] = useState(false)
  const [searchQuery, setSearchQuery] = useState('');
  const [sortOrder, setSortOrder] = useState('latest');
  const [jobTypes, setJobTypes] = useState('all');
  const [errorMsg, setErrorMsg] = useState("Something Went Wrong")
  const [loading, setLoading] = useState(false)
  const [isDelete, setIsDelete] = useState({
    modelOpen: false,
    deleteId: ""
  })
  const { isAdmin, setIsAdmin, isLogin, setIsLogin } = useContext(AuthContex)

  const [formData, setFormData] = useState({
    _id: "",
    title: "",
    skills: "",
    stipend: "",
    responsibilities: "",
    location: "",
    price: '',
    isFullTime: false,  
    twoMonth:'',
    threeMonth:'',
    sixMonth:'',
    fullTime:'' 

  });
  const [submitFormError, setSubmitFormError] = useState({
    success: false,
    loading: false,
    error: false,
    isDataFilled: false
  })
  const navigate = useNavigate();
  const handleEdit = (item) => {
    setIsEditJob(true)
    setFormData(item)
  }
  const handleAdd = (item) => {
    setIsAddJob(true)
  }
  const addedJobRoles = async () => {
    try {
      const { data } = await axios.post(`${BASE_URL}/position/create-blog`, formData, {
        withCredentials: true
      });
      if (data?.success) {
        setFormData({
          title: "",
          skills: "",
          stipend: "",
          responsibilities: "",
          location: "",
          isFullTime: false,
          twoMonth:'',
    threeMonth:'',
    sixMonth:'',
    fullTime:'' 
        })
        setSubmitFormError({ ...submitFormError, loading: false, success: true })
        setIsAddJob(false)
        await getData()
      } else {
        setErrorMsg(data?.msg)
      }
    } catch (error) {
      setSubmitFormError({ ...submitFormError, loading: false, error: true })
    }
  }
  const editedJobRoles = async () => {
    try {
      const { data } = await axios.put(`${BASE_URL}/position/update-blog/${formData._id}`, formData, {
        withCredentials: true
      });
      if (data?.success) {
        setFormData({
          title: "",
          skills: "",
          stipend: "",
          responsibilities: "",
          location: "",
          isFullTime: false,
          twoMonth:'',
    threeMonth:'',
    sixMonth:'',
    fullTime:'' 
        })
        setSubmitFormError({ ...submitFormError, loading: false, success: true })
        setIsEditJob(false)
        await getData()
      } else {
        setErrorMsg(data?.msg)
      }
    } catch (error) {
      setSubmitFormError({ ...submitFormError, loading: false, error: true })
    }
  }

  const deletedJobRoles = async (id) => {
    try {
      const { data } = await axios.delete(`${BASE_URL}/position/delete-blog/${id}`, {
        withCredentials: true
      });
      if (data?.success) {
        setSubmitFormError({ ...submitFormError, loading: false, success: true })
        setIsDelete({
          modelOpen: false,
          deleteId: ""
        })
        await getData()
      } else {
        setErrorMsg(data?.msg)
      }

    } catch (error) {

    }
  }
  const getData = async () => {
    try {
      setLoading(true)
      const { data } = await axios.get(`${BASE_URL}/position/get-all-blog`, {
        withCredentials: true
      })
      if (data?.success) {
        setBlogs(data?.blogs)
        setFilteredBlogs(data?.blogs)
      }
      setLoading(false)
    } catch (error) {
      setSubmitFormError({ ...submitFormError, loading: false, error: true })
      setLoading(false)
    }
  }
  useEffect(() => {
    getData()
  }, [])
  const applyFilter = () => {
    let filteredUsers = blogs.filter((item) => {
      const matchesSearchQuery =
        item.title.toLowerCase().includes(searchQuery.toLowerCase())
      return matchesSearchQuery;
    });

    filteredUsers = filteredUsers.sort((a, b) => {
      if (sortOrder === 'latest') {
        return new Date(b.createdAt) - new Date(a.createdAt);
      } else {
        return new Date(a.createdAt) - new Date(b.createdAt);
      }
    });
    filteredUsers = filteredUsers.filter((item) => {
      if (jobTypes === 'all') return item;
      else if (jobTypes === 'full-time') return item.isFullTime === "true";
      else {
        return item.isFullTime === "false";
      }
    });
    setFilteredBlogs(filteredUsers);
  };
  useEffect(() => {
    setLoading(true)
    const time = setTimeout(() => {
      applyFilter();
      setLoading(false)
    }, 500);
    return () => clearTimeout(time);
  }, [searchQuery, sortOrder, blogs, jobTypes]);

  return (<>
    <Box className={styles.careerBody}>
      <div className={styles.textLoop}>
        Career Oppurtunity
        <span className={styles.span}>
          <Typewriter
            options={{
              strings: "at Aminurmus Technology..",
              autoStart: true,
              loop: true,
            }}
          />
        </span>
      </div>
      <div className={styles.container}>
        <TextField
          id="outlined-basic"
          label="Search by course name"
          variant="outlined"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        <div style={{ margin: 'auto', width: '80%' }}>
          <FormControl fullWidth style={{ margin: 'auto', width: '30%' }}>
            <InputLabel variant="standard" htmlFor="uncontrolled-native">
              Job Types
            </InputLabel>
            <NativeSelect
              value={jobTypes}
              onChange={(e) => setJobTypes(e.target.value)}
            >
              <option value={"all"} style={{ textAlign: 'center' }}>All</option>
              <option value={"intern"} style={{ textAlign: 'center' }}>Intern</option>
              <option value={"full-time"} style={{ textAlign: 'center' }}>Full Time</option>
            </NativeSelect>
          </FormControl>
          <FormControl fullWidth style={{ marginLeft: '30px', width: '30%' }}>
            <InputLabel variant="standard" htmlFor="uncontrolled-native">
              Sort
            </InputLabel>
            <NativeSelect
              value={sortOrder}
              onChange={(e) => setSortOrder(e.target.value)}
            >
              <option value={"latest"} style={{ textAlign: 'center' }}>Latest</option>
              <option value={"earliest"} style={{ textAlign: 'center' }}>Earliest</option>
            </NativeSelect>
          </FormControl>
        </div>


      </div>
      {
        isAdmin &&
        <Box><Button variant="outlined" className={styles.filterBtn} style={{ color: "white", marginLeft: "22px", padding: "10px 10px" }} onClick={() => handleAdd()}>Add Job Roles</Button></Box>
      }
      {
        loading ? <CarrerLoading/> :
          <div style={{ margin: "22px", paddingBottom: "60px" }}>
            {
              filteredBlogs?.length === 0 ? <NoPagesFound /> :
                (filteredBlogs).map((item) =>
                  <Box style={{ width: "95vw", marginTop: "22px" }}>
                    <Grid container md={12} spacing={5}>
                      <Grid item md={10}>

                        <Typography variant='h4' style={{ fontWeight: "600" }}> {item.title} ({item.location})
                          {
                            isAdmin && <>
                              <Button variant="outlined" style={{ marginLeft: "12px" }} onClick={() => handleEdit(item)}>Edit</Button>
                              <Button variant="outlined" color="error" style={{ marginLeft: "12px" }} onClick={() => setIsDelete({ modelOpen: true, deleteId: item._id })}>Delete</Button>
                            </>
                          }
                        </Typography>
                        <Typography variant='h6' style={{ fontWeight: "600" }}> Skill: <span style={{ fontSize: "16px", fontWeight: "400" }}>{item.skills}</span></Typography>
                        {item.isFullTime && <Typography variant='h6' style={{ fontWeight: "600" }}> Stipend: <span style={{ fontSize: "16px", fontWeight: "400" }}>{item.stipend}</span></Typography>}
                        <Typography variant='h6' style={{ fontWeight: "600" }}> Responsibilities: <span style={{ fontSize: "16px", fontWeight: "400" }}>{item.responsibilities}</span></Typography>

                      </Grid>
                      <Grid item md={2} className={styles.btn}
                      >
                        <span onClick={() => navigate(`/applyform/${item.title}/${item.isFullTime}`)} className={styles.btnLink} target="_blank" style={{ width: "115px", height: '40px' }} >Apply</span>
                      </Grid>
                    </Grid>
                    <Modal
                      open={isEditJob}
                      onClose={() => setIsEditJob(!isEditJob)}
                      aria-labelledby="modal-modal-title"
                      aria-describedby="modal-modal-description"
                    >
                      <Box sx={{ ...style, width: "50vw" }} >
                        <ContactForm >
                          <ContactTitle>Edit Job Roles</ContactTitle>
                          <TextField
                            id="standard-error-helper-text"
                            placeholder="Title"
                            variant="standard"
                            value={formData.title}
                            onChange={(e) => setFormData({ ...formData, title: e.target.value })}
                          />
                          <TextField
                            id="standard-error-helper-text"
                            placeholder="Skills"
                            variant="standard"
                            value={formData.skills}
                            onChange={(e) => setFormData({ ...formData, skills: e.target.value })}
                          />
                          <TextField
                            id="standard-error-helper-text"
                            placeholder="Stipend"
                            variant="standard"
                            value={formData.stipend}
                            onChange={(e) => setFormData({ ...formData, stipend: e.target.value })}
                          />
                          <TextField
                            id="standard-error-helper-text"
                            placeholder="Location"
                            variant="standard"
                            value={formData.location}
                            onChange={(e) => setFormData({ ...formData, location: e.target.value })}
                          />
                          <TextField
                            id="standard-error-helper-text"
                            placeholder="Is Full Time"
                            variant="standard"
                            value={formData.isFullTime}
                            onChange={(e) => setFormData({ ...formData, isFullTime: e.target.value })}
                          />
                          <TextField
                            id="standard-error-helper-text"
                            placeholder="responsibilities"
                            variant="standard"
                            value={formData.responsibilities}
                            onChange={(e) => setFormData({ ...formData, responsibilities: e.target.value })}
                          />
                          <TextField
            id="standard-error-helper-text"
            placeholder="2 Month Price"
            variant="standard"
            value={formData.twoMonth}
            onChange={(e) => setFormData({ ...formData, twoMonth: e.target.value })}
          />
          <TextField
            id="standard-error-helper-text"
            placeholder="3 Month Price"
            variant="standard"
            value={formData.threeMonth}
            onChange={(e) => setFormData({ ...formData, threeMonth: e.target.value })}
          />
          <TextField
            id="standard-error-helper-text"
            placeholder="6 Month Price"
            variant="standard"
            value={formData.sixMonth}
            onChange={(e) => setFormData({ ...formData, sixMonth: e.target.value })}
          />
          <TextField
            id="standard-error-helper-text"
            placeholder="full time Price"
            variant="standard"
            value={formData.fullTime}
            onChange={(e) => setFormData({ ...formData, fullTime: e.target.value })}
          />
                          <Button variant="outlined" onClick={() => editedJobRoles()}>Save</Button>
                        </ContactForm>
                        {
                          submitFormError.success &&
                          <Alert severity="success" color="success">
                            Job Roles Edited successfully
                          </Alert>
                        }
                        {
                          submitFormError.error &&
                          <Alert severity="error" color="error">
                            {errorMsg}
                          </Alert>
                        }
                        {
                          submitFormError.isDataFilled &&
                          <Alert severity="error" color="error">
                            Please fill proper data
                          </Alert>
                        }
                      </Box>
                    </Modal>
                  </Box>
                )
            }
          </div>
      }
    </Box>
    <Modal
      open={isAddJob}
      onClose={() => setIsAddJob(!isAddJob)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={{ ...style, width: "50vw" }} >
        <ContactForm >
          <ContactTitle>Add Job Roles</ContactTitle>
          <TextField
            id="standard-error-helper-text"
            placeholder="Title"
            variant="standard"
            value={formData.title}
            onChange={(e) => setFormData({ ...formData, title: e.target.value })}
          />
          <TextField
            id="standard-error-helper-text"
            placeholder="Skills"
            variant="standard"
            value={formData.skills}
            onChange={(e) => setFormData({ ...formData, skills: e.target.value })}
          />
          <TextField
            id="standard-error-helper-text"
            placeholder="Stipend"
            variant="standard"
            value={formData.stipend}
            onChange={(e) => setFormData({ ...formData, stipend: e.target.value })}
          />          
          <TextField
            id="standard-error-helper-text"
            placeholder="Location"
            variant="standard"
            value={formData.location}
            onChange={(e) => setFormData({ ...formData, location: e.target.value })}
          />
          <TextField
            id="standard-error-helper-text"
            placeholder="Is Full Time"
            variant="standard"
            value={formData.isFullTime}
            onChange={(e) => setFormData({ ...formData, isFullTime: e.target.value })}
          />
          <TextField
            id="standard-error-helper-text"
            placeholder="responsibilities"
            variant="standard"
            value={formData.responsibilities}
            onChange={(e) => setFormData({ ...formData, responsibilities: e.target.value })}
          />
          <TextField
            id="standard-error-helper-text"
            placeholder="2 Month Price"
            variant="standard"
            value={formData.twoMonth}
            onChange={(e) => setFormData({ ...formData, twoMonth: e.target.value })}
          />
          <TextField
            id="standard-error-helper-text"
            placeholder="3 Month Price"
            variant="standard"
            value={formData.threeMonth}
            onChange={(e) => setFormData({ ...formData, threeMonth: e.target.value })}
          />
          <TextField
            id="standard-error-helper-text"
            placeholder="6 Month Price"
            variant="standard"
            value={formData.sixMonth}
            onChange={(e) => setFormData({ ...formData, sixMonth: e.target.value })}
          />
          <TextField
            id="standard-error-helper-text"
            placeholder="full time Price"
            variant="standard"
            value={formData.fullTime}
            onChange={(e) => setFormData({ ...formData, fullTime: e.target.value })}
          />
          <Button variant="outlined" onClick={addedJobRoles}>Add</Button>
        </ContactForm>
        {
          submitFormError.success &&
          <Alert severity="success" color="success">
            Job Roles Added successfully
          </Alert>
        }
        {
          submitFormError.error &&
          <Alert severity="error" color="error">
            {errorMsg}
          </Alert>
        }
        {
          submitFormError.isDataFilled &&
          <Alert severity="error" color="error">
            Please fill proper data
          </Alert>
        }
      </Box>
    </Modal>
    <Modal
      open={isDelete?.modelOpen}
      onClose={() => setIsDelete({ modelOpen: "false", deleteId: "" })}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Delete Job Roles
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          Are you sure want to delete
        </Typography>
        <Button variant="outlined" sx={{ mt: 2 }} onClick={() => deletedJobRoles(isDelete?.deleteId)}>Comferm</Button>

      </Box>
    </Modal>
  </>
  )
}

