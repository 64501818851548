import React, { useEffect, useState } from 'react';
import { Button, Container, Typography, Box } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Typewriter from 'typewriter-effect';
import styles from './myStyles.module.css'; 

const items = [
  {
    img: "https://img.freepik.com/premium-photo/man-blue-shirt-tie-looking-camera-with-blue-background_746565-191296.jpg?uid=R160194662&ga=GA1.1.347569992.1706451951&semt=ais_hybrid",
    name: 'Rajesh Sharma',
    postion:"CEO of Tech Innovators Pvt Ltd",
    description: "Aminurmus Technology Pvt Ltd has been instrumental in transforming our digital presence. Their expertise in web development and AI/ML solutions has helped us achieve our business goals with innovative, tailor-made strategies. We couldn't be happier with the results!",
  },
  {
    img: "https://img.freepik.com/premium-photo/man-blue-shirt-tie-looking-camera-with-blue-background_746565-191280.jpg?uid=R154986372&ga=GA1.1.1902770949.1720603423&semt=ais_hybrid",
    name: 'Rahul Kumar',
    postion:"Founder of Startup Solutions",
    description: "From initial consultation to final launch, the service provided by Aminurmus Technology has been top-notch. Their attention to detail, coupled with their technical expertise, has made our project a huge success. We look forward to continuing our partnership with them for future endeavors.",
  },
  {
    img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT9gp-_ECxjxKvd9UosODbT3mw2JV9_M_2Qwg&s",
    name: 'Ankit Choudhary',
    postion:"Founder- Soluflex Inc.",
    description: "The team at Aminurmus Technology exceeded our expectations with their outstanding web development and UI/UX design services. They truly understood our vision and brought it to life with a modern, user-friendly interface that has significantly improved our customer engagement.",
  },
  {
    img: "https://img.freepik.com/premium-photo/business-people-suit-studio-concept_53876-79567.jpg?uid=R160194662&ga=GA1.1.347569992.1706451951&semt=ais_hybrid",
    name: 'Priya Verma',
    postion:"Marketing Head at Market Leaders Co.",
    description: "Aminurmus Technology has been a reliable partner in our journey toward digital transformation. Their data science and SEO optimization services have provided us with the insights and visibility needed to stay ahead in a competitive market. We are grateful for their unwavering support and efficiency.",
  },
  {
    img: "https://img.freepik.com/premium-photo/photo-document-passport-id-mature-caucasian-man-suit_262388-3596.jpg",
    name: 'Emily Smith',
    postion:"Product Manager at Global Enterprises Ltd",
    description: "From initial consultation to final launch, the service provided by Aminurmus Technology has been top-notch. Their attention to detail, coupled with their technical expertise, has made our project a huge success. We look forward to continuing our partnership with them for future endeavors.",
  },
  {
    img: "https://t4.ftcdn.net/jpg/07/57/59/25/240_F_757592568_v96NBkRahyTl9QFa99qpcmePYnfoXwZq.jpg",
    name: 'Chloe Martin',
    postion:"CEO of AppSolutions Ltd, Australia",
    description: "The team at Aminurmus Technology was instrumental in the successful launch of our mobile application. Their expertise in Android/iOS development, combined with their commitment to timely delivery, made the entire process smooth and stress-free. We highly recommend them for any tech needs.",
  },
];

const Slider = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? items.length - 1 : prevIndex - 1
    );
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === items.length - 1 ? 0 : prevIndex + 1
    );
  };
  useEffect(()=>{
    setTimeout(()=>{
        handlePrev()
    },5000)
  })

  return (<div className={styles.testimonialBody} id='Testimonials'>
        <div className={styles.textLoop}>
        What People 
            <span className={styles.span}>
          <Typewriter
              options={{
                  strings: "Say About Us...",
                  autoStart: true,
                  loop: true,
              }}
          />
            </span>
        </div>
        <div className={styles.discr}>
        At Aminurmus Technology Pvt Ltd, we specialize in delivering innovative digital solutions that drive growth and efficiency. Our expertise spans across Android/iOS Development, AI/ML Solutions, Web Development, UI/UX Design, Testing/Launching, and SEO Optimization. We partner with businesses to create cutting-edge applications, optimize digital experiences, and ensure seamless product launches, all while enhancing online visibility through strategic SEO practices. Our commitment is to provide top.
        </div>
    <Box style={{display:"flex",justifyContent:"center"}}>
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        width: '90%', 
        height: '500px',
      }}
    >
      {/* Left Arrow Button */}
      <Button
        onClick={handlePrev}
        // variant="contained"
        sx={{
          position: 'absolute',
          left: 0,
          top: '50%',
          transform: 'translateY(-50%)',
          zIndex: 1,
        }}
      >
        <ArrowBackIosIcon />
      </Button>

      {/* Content Container */}
      <Container
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          textAlign: 'center',
          // border: '1px solid #ccc',
          // borderRadius: '8px',
          padding: '20px',
          width: '90vw',
          height:"300px"
        }}
      >
        <Box
          component="img"
          src={items[currentIndex].img}
          alt={items[currentIndex].name}
          sx={{
            width: '200px',
            height: '200px',
            borderRadius: '50%',
            marginBottom: '16px',
            objectFit: "cover",
          }}
        />
        <Typography variant="h4" className={styles.heading} style={{fontWeight:700}}>{items[currentIndex].name}</Typography>
        <Typography variant="h5" className={styles.subHeading} style={{fontWeight:500}}>{items[currentIndex].postion}</Typography>
        <Typography variant="body2" color="textSecondary" className={styles.textFeild}>
          {items[currentIndex].description}
        </Typography>
      </Container>

      {/* Right Arrow Button */}
      <Button
        onClick={handleNext}
        // variant="contained"
        sx={{
          position: 'absolute',
          right: 0,
          top: '50%',
          transform: 'translateY(-50%)',
          zIndex: 1,
        }}
      >
        <ArrowForwardIosIcon />
      </Button>
    </Box>
    </Box>
    </div>
  );
};

export default Slider;

