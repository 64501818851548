import React from 'react';
import { Box, Typography, Button, Container } from '@mui/material';
import { styled } from '@mui/system';
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { NoPagesImage } from '../NoPagesImage';
import { useNavigate } from 'react-router-dom';
const CustomNameTypography = styled(Typography)({
  fontFamily: "Manrope",
  fontSize: "20px",
  fontStyle: "normal",
  fontWeight: 700,
  lineHeight: "normal",
  letterSpacing: "0.15px",
  color: '#172B4D',
  textAlign: 'center'
});
const TextTypography = styled(Typography)({
  fontFamily: "Inter",
  fontSize: "18px",
  fontStyle: "normal",
  fontWeight: 600,
  lineHeight: "normal",
  letterSpacing: "0.2px",
  color: '#344563',
  textAlign: 'center'
});
const CustomHomepageButton = styled(Button)({
  padding: '6px 10px 6px 16px',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '6px',
  borderRadius: '4px',
  border: '0.5px solid #3B6DEF',
  color: '#3B6DEF',
  marginTop:'8px',
  backgroundColor: 'transparent',
  '&:hover': {
    backgroundColor: '#3B6DEF',
    color: '#FFFFFF',
  },
});
const NoPagesFound = ({contain}) => {  
    const navigate=useNavigate()
    return (
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          minHeight="60vh"
        >
          <Box textAlign="center">
            <NoPagesImage/>
          </Box>
          <TextTypography>
            {contain}.
          </TextTypography>         
          <Box textAlign="center">
          <CustomHomepageButton sx={{ marginRight: 1 }} onClick={()=>navigate("/")} >
              Go to the Homepage
              <OpenInNewIcon sx={{ width: 18, height: 18 }} />
            </CustomHomepageButton>
          </Box>
        </Box>
    );
  };
  export default NoPagesFound;