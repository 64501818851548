import * as React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';
import styles from './styles.module.css';
function Loading() {
  return (
    <Grid style={{margin:'12px'}} >
      {Array.from(new Array(6)).map((_, index) => (
        <Box key={index} className={styles.blogPost}>
          <Grid container spacing={14}>
            <Grid item xs={10} md={10}><Skeleton width="100%"  height={30}/></Grid>
            <Grid item xs={2} md={2}><Skeleton width="100%" height={50}/></Grid>
          </Grid>
          <Box sx={{ pt: 0.5 }}>
            <Skeleton width="60%"/>
            <Skeleton width="40%" height={30}/>
            <Skeleton width="50%" height={40}/>
            
          </Box>
        </Box>
      ))}
    </Grid>
  );
}

export default function CarrerLoading() {
  return (
    <Box sx={{ overflow: 'hidden' }}>
      <Loading />
    </Box>
  );
}
